import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import usePagination from '../../../hooks/usePagination';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import { Sidebar } from '../Layout/Sidebar';

const Guarantee = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageCount, endOffset, itemOffset, itemsPerPage, setPageCount, setItemOffset, setItemsPerPage } = usePagination(data)
  let currentItems = data?.slice(itemOffset, endOffset);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/' + id, true).delete({});
      if(res.code == 204){
        Swal.fire({
          title: 'موفق',
          text: 'گارانتی با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      dispatch(setLoading(false));
      await fetchData()
    }
  }

  const list = () => {
    const rows = [];

    currentItems?.map((banner: any, index) => {
      rows.push(
        <tr className="dashTr2">
          <td className="">
            <i className="trash clickable" onClick={() => deleteItem(banner.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/guarantee/edit/' + banner.id)}></i>
            <i className="factorSvg clickable" onClick={() => navigate('/dashboard/guarantee/print/' + banner.id)}></i>
          </td>
          <td className="">{banner.brand?.title}</td>
          <td className="">{banner.isActive ? 'فعال' : 'غیر فعال'}</td>
          <td>{banner.product?.title}</td>
          <td className="">{banner?.user?.name + ' ' + banner.user?.lastName}</td>
          <td>
            {banner?.serial}
          </td>
          <td>{++index}</td>
        </tr>
      )
    })

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/', true).get({
      page: searchParams.get('page') || 1,
      perPage: itemsPerPage,
      query: query
    });

    if(res.code == 200){
      setData(res.data.guarantees);
      setPageCount(Math.floor(res.data.count / itemsPerPage))
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [searchParams.get('page'), itemsPerPage, query]);

  return(
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست گارانتی ها</h1>
        <div className="searchContainer">
          <div className="keepRight svgContainer">
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/guarantee/add')}>
            <p>افزودن گارانتی جدید</p>
            <i className="addPlus"></i>
          </span>
          </div>
          <div className='itemsPerPage'>
            <label htmlFor="itemPerPage">تعداد در صفحه</label>
            <input id='itemPerPage' defaultValue={itemsPerPage} onChange={(input) => input.target.value && Number(input.target.value) > 0 && setItemsPerPage(Number(input.target.value))}/>
          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input) => {
              setQuery(input.target.value);
              setSearchParams({['page']: '1'})
            }} />
          </div>
        </div>
      <table>
        <thead>
        <tr className="dashTr1">
          <th className="">عملیات</th>
          <th className="">برند</th>
          <th>وضعیت</th>
          <th>محصول</th>
          <th className="">کاربر</th>
          <th>سریال</th>
          <th>ردیف</th>
        </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="بعدی >"
          onPageChange={(event) => {
            setSearchParams({['page']: (Number(event.selected) + 1).toString()})
            setItemOffset((event.selected * itemsPerPage) % data.length);
          }}
          initialPage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< قبلی"
          renderOnZeroPageCount={null}
          className="pagination"
          pageClassName="paginationBreak"
          previousClassName="paginationBreak"
          nextClassName="paginationBreak"
          activeClassName="paginationActive"
        />
      </main>
      </body>
    </>
  )
}
export default Guarantee
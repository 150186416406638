import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useUrlParam from '../../hooks/useUrlParam';

export const MenuRow = ({ attribute }: any) => {
  const [active, setActive] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const [query, setQuery] = useState('');
  const { setUrlParam, removeUrlParam } = useUrlParam();

  const list = () => {
    const rows = []

    rows.push(<input placeholder='جستجو' value={query} onChange={(input) => setQuery(input.target.value)} />)

    attribute.attributeValues?.filter(e => e.title?.toLowerCase().includes(query.toLowerCase())).map((value, index) => {
      const checked = searchParam.getAll(`tags[]`).includes(value.slug);

      rows.push(
        <div className="sexShowMore" key={"value" + index}>
          <p>{value.title}</p>
          <i className={checked ? 'checkBoxChecked' : 'checkBox'} onClick={() => checked ? removeUrlParam(`tags[]`, value.slug) : setUrlParam(`tags[]`, value.slug)}></i>
        </div>
      )
    })

    return rows
  };

  return (
    <li className="catLi">
      <div className="catRow">
        <p>{attribute.title}</p>
        <i className={!active ? 'plusIcon' : 'collapsIcon'} onClick={() => setActive(!active)}></i>
      </div>
      {active && list()}
    </li>
  );
};
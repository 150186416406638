const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0
}

const formatPrice = (price: number = 1, min = 0, max = 8) => {
  if (!price) return 0;
  return price.toLocaleString('en-US', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};


const isNumeric = (str: string) => {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const omit = (keys, obj) => {
  if (!keys.length) return obj
  const { [keys.pop()]: omitted, ...rest } = obj;
  return omit(keys, rest);
}

const getMainPic = (array) => array.length > 0 ? array?.find(e => e.code == "main") || array[0] : undefined
const selectFormatter = (array: any[], value: string, label: string, firstOption: string = '') => {
  const newArray = [];

  if (firstOption){
    newArray.push({
      value: '',
      label: firstOption
    })
  }

  array?.length && Array.isArray(array) && array?.map(e => newArray.push({
    value: e[value],
    label: e[label]
  }))

  return newArray
};
export default {
  isEmpty,
  omit,
  selectFormatter,
  formatPrice,
  getMainPic,
  isNumeric
};
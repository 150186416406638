import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore from 'swiper'
import { Autoplay, Navigation, Pagination  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useTicker from '../../hooks/useTicker';
import Header from '../../layouts/Header';
import ProductCard from '../../layouts/ProductCard';
import { popupSlice } from '../../services/reducers';
import { setLoading } from '../../services/reducers/homeSlice';
import { cart, favorites } from '../../services/reducers/userSlice';
import restApi from '../../services/restApi';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';
import Gallery from './Gallery';
import InfoRow from './InfoRow';
import TooltipImage from './TooltipImage';

const SingleProduct = () => {
  const { slug } = useParams();
  const [productGroup, setProductGroup] = useState<any>();
  const [mainPic, setMainPic] = useState('');
  const [count, setCount] = useState(1);
  const userReducer = useAppSelector(state => state.userReducer)
  const dispatch: any = useDispatch();
  const fav = userReducer?.favorites?.filter((e) => e.productId == productGroup?.id)?.length > 0;

  const {
    calcPrice,
    ticker
  } = useTicker();

  const similarProductList = () => {
    const rows: ReactElement[] = [];

    productGroup?.similarProducts?.map((similarProduct, index) => rows.push(
      <tr>
        <td><button className="tableAddToCard clickable" onClick={() => send(similarProduct.id)}>افزودن به سبد خرید</button></td>
        <td>{tools.formatPrice(similarProduct.price)}</td>
        <td>{similarProduct.status == 1 ? 'موجود' : 'ناموجود'}</td>
        <td>{similarProduct.sku}</td>
        <td><a href={`/product/${similarProduct.slug}`}>{similarProduct.title}</a></td>
        <td><TooltipImage src={tools.getMainPic(similarProduct.medias)?.url}/></td>
        <td>{++index}</td>
      </tr>
    ))

    return rows;
  };

  const list = () => {
    const rows: ReactElement[] = [];
    productGroup?.attributeProducts?.filter(e => !e.attribute?.isTechnology).map((attrProd, index) => {
      rows.push(
        <tr className={index % 2 == 0 ? 'lightGreyBack' : ''}>
          <td colSpan={2}>{attrProd.attributeValue.title}</td>
          <td colSpan={2}>{attrProd.attribute.title}</td>
          <td colSpan={1}>{++index}</td>
        </tr>
      );
    });

    return rows;
  };

  const techList = () => {
    const rows: ReactElement[] = [];
    productGroup?.attributeProducts?.filter(e => e.attribute?.isTechnology)?.map((attrProd, index) => {
      rows.push(
        <tr className={index % 2 == 0 ? 'lightGreyBack' : ''}>
          <td colSpan={2}>{attrProd.attributeValue.description}</td>
          <td colSpan={2}>{attrProd.attribute.description}</td>
          <td colSpan={1}>{attrProd.attributeValue.title}</td>
          <td colSpan={1}>{attrProd.attribute.title}</td>
          <td colSpan={1}>{++index}</td>
        </tr>
      );
    });

    return rows;
  };

  const favorite = async () => {
    let res;
    if (fav) {
      res = await restApi(process.env.REACT_APP_BASE_URL + '/product/favorite/' + productGroup?.id, true).delete({});
    } else {
      res = await restApi(process.env.REACT_APP_BASE_URL + '/product/favorite/' + productGroup?.id, true).post({});
    }

    if (res.code == 200){
      dispatch(favorites());
      Swal.fire({
        title: 'موفق',
        text: `کالا با موفقیت ${!fav ? 'به علاقه مندی ها اضافه': 'از علاقه مندی ها حذف'} شد`,
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: 'مشکلی پیش آمده لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.',
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
  };

  const send = async (productId) => {

    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/order', true).post({
      product: productId,
      count: 1
    });

    if (res.code == 200) {
      dispatch(cart());
      Swal.fire({
        title: 'موفق',
        text: 'کالا ها با موفقیت به سبد خرید اضافه شدند',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          // navigate('/cart');
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const fetchItem = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/product/' + slug).get();

    if (res.code == 200) {
      setProductGroup(res.data);
      setMainPic(tools.getMainPic(res.data?.medias)?.url);
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchItem();
  }, []);

  return (
    <>
      <Header/>
      <main className="singleProductMain">
        <div className="breadCrumbContainer">
          {/* <p className="breadCrumb3">{productGroup?.category?.title}</p> */}
        </div>
        <section className="singleProductTopSection">
          <div className="imgContainer">
            {productGroup?.medias?.slice(0, 6).map((media, index) => index != productGroup?.medias?.slice(0, 6).length - 1 ?
              <img className={`imgSingle ${media.url == mainPic ? 'selected' : ''}`} src={media.url} onClick={() => setMainPic(media.url)}/> :
              <img className={`imgSingle`} src={media.url} onClick={() => dispatch(popupSlice.middle(<Gallery images={productGroup?.medias}/>))}/>)}
          </div>
          <div>
            <section className="imgInfo">
              <div>
                {productGroup?.tags?.map((tag, index) =>
                  <a className="linkNone" href={`/tag/${tag.slug}`} target="_blank"><span className="productTag" key={'tag' + index}>{tag.title}</span></a>
                )}
              </div>
              <div>
                <p className="singleCode">{productGroup?.model}</p>
                <a className="singleName" href={`/brand/${productGroup?.brand?.slug}`}>{productGroup?.brand?.title}</a>
              </div>
            </section>
            <img className="singleImg" src={mainPic}/>
          </div>
          <div className="productInfo">
            <h1 className="productBrand">{productGroup?.title}</h1>
            <span className="productSku">
            <h4 className="sku">SKU</h4>
            <h4 className="skuNumber">{productGroup?.sku}</h4>
              </span>
            {productGroup?.status == 1 &&
              <>
                <h1 className="price1">DHs{calcPrice(productGroup?.discountPrice || productGroup?.price)}</h1>
                {productGroup?.discountPrice && <h1 className="discount"><s>DHs{calcPrice(productGroup?.price)}</s></h1>}
                <div className="persianPrice">
                  <h1 className="priceNumber">{calcPrice(ticker(productGroup?.discountPrice || productGroup?.price))} </h1>
                  <h2 className="priceText"> ~ تومان</h2>
                </div>
              </>
            }
            <InfoRow title="توضیحات" description={[productGroup?.longText]}/>
            <div className="productButtons">
                {/* <div className="quantityButtom"> */}
                {/*   <i className="tablePlusIcon" onClick={() => setCount(prevState => prevState + 1)}></i> */}
                {/*   <input className="quantityNumber" value={count} name={`counts[]`} onChange={(input: any) => setCount(input.target.value)}/> */}
                {/*   <i className="tableCollapsIcon" onClick={() => setCount(prevState => prevState - 1)}></i> */}
                {/* </div> */}
              {productGroup?.status == 1 ?
                <span className="tableAddToCard clickable" onClick={() => send(productGroup?.id)}>افزودن به سبد خرید</span>
                :
                <span className="tableAddToCard clickable" >ناموجود</span>
              }

              <i className={fav ? 'tableHeartIconFilled' : 'tableHeartIcon'} onClick={() => {
                favorite();
              }}></i>
            </div>
          </div>
        </section>
        <section className="singleProductSection similarProductSec">
          <h2>{productGroup?.model} (s)</h2>
          <div className="similarProductContainer">
            <table className="skuTable">
              <thead>
              <tr>
                <th></th>
                <th>قیمت</th>
                <th>وضعیت</th>
                <th>SKU</th>
                <th>عنوان</th>
                <th>تصویر</th>
                <th>ردیف</th>
              </tr>
              </thead>
              <tbody>
              {similarProductList()}
              </tbody>
            </table>

          </div>
        </section>
        <section className="singleProductSection">
          <h2>مشخصات فنی</h2>
          <table className="skuTable">
            <thead>
            <tr>
              <th colSpan={2}>توضیحات</th>
              <th colSpan={2}>عنوان</th>
              <th colSpan={1}>ردیف</th>
            </tr>
            </thead>
            <tbody>
            {list()}
            </tbody>
          </table>
          <h2>تکنولوژی ها</h2>
          <table className="skuTable">
            <thead>
            <tr>
              <th colSpan={2}>توضیحات</th>
              <th colSpan={2}>مزایا</th>
              <th colSpan={1}>مقدار</th>
              <th colSpan={1}>عنوان</th>
              <th colSpan={1}>ردیف</th>
            </tr>
            </thead>
            <tbody>
            {techList()}
            </tbody>
          </table>
        </section>
      </main>
    </>
  );
};
export default SingleProduct;